import { useLoginProfile } from '@/auth/useLoginProfile'
import MainLayout from '@/layouts/MainLayout'
import { Box } from '@mui/material'
import type { NextPage } from 'next'
import dynamic from 'next/dynamic'

const PageToScene = dynamic(() => import('@/scene/PageToScene'), { ssr: false })

const CloverMain: NextPage = () => {
  // Todo. 추후에 관리자, 일반 워커를 구분하는 로직 추가될 예정(sim jin woo)
  // 현재 로그인되어있는 사용자 정보 가져오기
  const loginProfile = useLoginProfile()

  const navId = loginProfile?.profile.navId

  if (loginProfile === undefined)
    return (
      <MainLayout>
        <PageToScene url="/pds/list" title="CROWD WORKER" />
      </MainLayout>
    )
  else if (navId === '2') {
    return (
      <MainLayout>
        <PageToScene url="/crowd-work/basic-main-home" title="메인 메뉴" />
      </MainLayout>
    )
  } else if (navId === '3') {
    return (
      <MainLayout>
        <PageToScene url="/crowd-work/pro-main-home" title="메인 메뉴" />
      </MainLayout>
    )
  } else if (navId === '4') {
    return (
      <MainLayout>
        <PageToScene url="/crowd-work/professor/pr1" title="메인 메뉴" />
      </MainLayout>
    )
  } else if (navId === '5') {
    return (
      <MainLayout>
        <PageToScene url="/crowd-work/worker-status" title="워커현황" />
      </MainLayout>
    )
  } else {
    return (
      <MainLayout>
        <PageToScene url="/pds/list" title="CROWD WORKER" />
      </MainLayout>
    )
  }
}

export default CloverMain
